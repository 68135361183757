  <template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="风险处理提报审核"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
          ><template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </div>
      <!-- 排序 -->
      <div class="choose" v-if="!conditionsFlag && !show && !showexamination">
        <div class="choose-div">
          <!-- <span class="choosetext" @click="conditions(0)"
            ><span>分类切换</span><van-icon name="exchange" />
          </span> -->
          <span class="choosetext" @click="conditions(0)"
            ><span>排序</span><van-icon name="ascending" />
          </span>
          <span class="choosetext" @click="conditions(1)"
            ><span>筛选</span><van-icon name="filter-o"
          /></span>
        </div>
      </div>
    </div>
    <van-calendar
      v-if="show"
      v-model="show"
      type="range"
      @confirm="onConfirm"
      color="#1989fa"
      :min-date="minDate"
      :max-date="maxDate"
      :poppable="false"
      ref="calendar"
      :allow-same-day="true"
      :default-date="null"
    >
    </van-calendar>
    <van-calendar
      v-if="showexamination"
      v-model="showexamination"
      type="range"
      @confirm="onConfirmexamination"
      color="#1989fa"
      :min-date="minDate"
      :max-date="maxDate"
      :poppable="false"
      :allow-same-day="true"
      :default-date="null"
    />
    <div
      class="content"
      :class="[!conditionsFlag ? '' : 'contentbox']"
      v-if="!show && !showexamination"
    >
      <div class="contentsorting" v-if="conditionsFlag">
        <van-tabs v-model="active">
          <!-- 点击排序 -->
          <van-tab>
            <template #title> 排序<van-icon name="ascending" /></template>
            <div class="sorting">
              <div class="sortingtit">排序方式</div>
              <div class="reverse" @click="reverseOrder(0)">
                倒序排序
                <van-icon name="success" v-if="sort == 0" color="#2D8AFF" />
              </div>
              <div class="reverse" @click="reverseOrder(1)">
                正序排序
                <van-icon name="success" v-if="sort == 1" color="#2D8AFF" />
              </div>
              <div class="sortingtit">排序属性</div>
              <div class="reverse" @click="sortProperties(0)">
                提报日期
                <van-icon name="success" v-if="sortType == 0" color="#2D8AFF" />
              </div>
              <div class="reverse" @click="sortProperties(1)">
                提报人
                <van-icon name="success" v-if="sortType == 1" color="#2D8AFF" />
              </div>
              <div class="reverse" @click="sortProperties(2)">
                申请单位
                <van-icon name="success" v-if="sortType == 2" color="#2D8AFF" />
              </div>
            </div>
          </van-tab>
          <!-- 点击筛选 -->
          <van-tab>
            <template #title>筛选<van-icon name="filter-o" /></template>
            <div class="sorting">
              <div class="sortingtit">审批状态</div>
              <div class="sortingbut">
                <van-button
                  type="default"
                  size="small"
                  @click="sortingbut(1)"
                  :class="{ issfxed: statusType == 1 }"
                  >待审批</van-button
                >
                <van-button
                  type="default"
                  size="small"
                  @click="sortingbut(2)"
                  :class="{ issfxed: statusType == 2 }"
                  >已通过</van-button
                >
              </div>
              <div class="sortingtit">编制单位</div>
              <div
                class="reverse"
                @click="handleDep(item.id)"
                v-for="item in userDep"
                :key="item.id"
              >
                {{ item.name }}
                <van-icon
                  name="success"
                  v-if="departmentId == item.id"
                  color="#2D8AFF"
                />
              </div>
              <div class="sortingtit">提报日期</div>
              <van-cell-group>
                <van-field
                  v-model="createDate"
                  placeholder="请选择"
                  @focus="dateReported"
                />
              </van-cell-group>
              <!-- <van-calendar
                v-model="show"
                type="range"
                @confirm="onConfirm"
                color="#1989fa"
                :min-date="minDate"
                :max-date="maxDate"
              /> -->
              <div class="sortingtit">审批日期</div>
              <van-cell-group>
                <van-field
                  v-model="auditDate"
                  placeholder="请选择"
                  @focus="dateReportedmexamination"
                />
              </van-cell-group>
              <!-- <van-calendar
                v-model="showexamination"
                type="range"
                @confirm="onConfirmexamination"
                color="#1989fa"
                :min-date="minDate"
                :max-date="maxDate"
                :allow-same-day="true"
              /> -->
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 列表 -->
      <van-empty
        :description="description"
        v-if="formConfig.data.length == 0 && !conditionsFlag"
      />
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="刷新成功"
        v-if="!conditionsFlag"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <lists
            :formConfig="formConfig"
            :hasUser="false"
            ref="far"
            @checked="checkedfun"
            @result="resultfun"
          />
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 其他页页尾 -->
    <div class="footer" v-if="conditionsFlag">
      <div class="left" @click="onReset">重置</div>
      <div class="right" @click="onCancel">确定</div>
    </div>
    <!-- 列表页页尾 -->
    <div class="footerindex" v-else v-show="statusType == 1 && !isEmpty">
      <div>
        <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
      </div>
      <van-button type="info" size="small" @click="through">通过</van-button>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  name: "gsnSupplierConfirm",
  data() {
    return {
      description: '没有需要审批的单据',
      checked: false,
      result: [],
      loading: false,
      finished: false,
      isLoading: false,
      active: 0,
      conditionsFlag: false,
      sort: 0,
      sortType: 0,
      statusType: 1,
      show: false,
      popshow: false,
      showexamination: false,
      createDate: "",
      auditDate: "",
      pageIndex: 1,
      pageSize: 10,
      submitBeginTime: "",
      submitEndTime: "",
      approveBeginTime: "",
      approveEndTime: "",
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      classificationType: "1", // 分类类型
      classification: [],
      departmentId: "",
      isEmpty: false,
      userDep: [],
      enterpriseId: "",
      formConfig: {
        data: [],
        columns: [
          {
            prop: "supplierCode",
            label: "供应商编码",
          },
          {
            prop: "productTypeName",
            label: "供应商分类",
          },
          {
            prop: "remark",
            label: "处理建议",
          },
        ],
        total: 0,
        url: "gsnRiskApplyDetails",
      },
    };
  },
  computed: {
    createBeginDate() {
      var date = new Date();
      date.setMonth(date.getMonth() - 3);
      date.toLocaleDateString();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
  },
  async created() {
    this.minDate = new Date(this.createBeginDate)
    await this.getUserDep();
    await this.getListMenu();
    await this.getList();
  },
  watch: {
    $route() {
      if (this.$route.query.audit) {
        this.onReset();
      }
    }
  },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.submitBeginTime = this.formatDate(start);
      this.submitEndTime = this.formatDate(end);
      this.createDate = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    dateReported() {
      this.show = true;
    },
    onConfirmexamination(date) {
      const [start, end] = date;
      this.showexamination = false;
      this.approveBeginTime = this.formatDate(start);
      this.approveEndTime = this.formatDate(end);
      this.auditDate = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    dateReportedmexamination() {
      this.showexamination = true;
    },
    sortingbut(o) {
      this.statusType = o;
    },
    sortProperties(o) {
      this.sortType = o;
    },
    reverseOrder(o) {
      this.sort = o;
    },
    handleClassification(o) {
      this.classificationType = o;
    },
    handleDep(o) {
      this.departmentId = o;
    },
    through() {
      if (this.$refs.far.result.length > 0) {
        Dialog.confirm({
          title: "审批",
          message: "是否同意",
        })
          .then(() => {
            let ids = []
            this.$refs.far.result.forEach(item => {
              ids.push(item.id)
            })
            const applyIds = ids.join(',')
            this.moreAuditApply(applyIds)
          })
          .catch(() => {
            // on cancel
          });
      } else {
        Toast.fail('请至少选择一个审核内容');
      }
    },
    checkAll() {
      if (this.checked === true) {
        this.$refs.far.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.far.$refs.checkboxGroup.toggleAll(false);
      }
    },
    checkedfun(data) {
      this.checked = data;
    },
    resultfun(data) {
      this.result = data;
    },
    conditions(o) {
      if (o == 0) {
        this.active = 0;
      } else if (o == 1) {
        this.active = 1;
      }
      this.conditionsFlag = true;
    },
    onClickLeft() {
      if (this.show || this.showexamination) {
        this.show = false
        this.showexamination = false
      } else {
        window.location.replace(process.env.VUE_APP_INDEX);
      }
    },
    onClickRight() {
      const query = {
        params: {
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
          auditFlag: this.statusType, // 审核状态（1审核中/2审核通过）
          type: "1",
          sortAttr: this.sortType,
          sortWay: this.sort,
          createDateBegin: this.submitBeginTime,
          createDateEnd: this.submitEndTime,
          auditDateBegin: this.approveBeginTime,
          auditDateEnd: this.approveEndTime,
        }
      }
      this.$router.push({ name: "gsnRiskApplySearch", query });
    },
    // 刷新成功
    onRefresh() {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.loadList();
    },
    async onLoad() {
      if (this.isEmpty) {
        this.loading = false;
        return
      }
      if (this.pageIndex >= this.formConfig.total) {
        this.finished = true
        return
      }
      this.pageIndex += 1;
      let params = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
        auditFlag: this.statusType, // 审核状态（1审核中/2审核通过）
        type: "1",
        sortAttr: this.sortType,
        sortWay: this.sort,
        createDateBegin: this.submitBeginTime,
        createDateEnd: this.submitEndTime,
        auditDateBegin: this.approveBeginTime,
        auditDateEnd: this.approveEndTime,
      };
      let data;
      if (this.statusType === 2) {
        params.isPhone = "true"
        data = await this.$api.gsn.getListAllSupplierQuery(params);
        data.rows.forEach((item) => {
          item.appStatus = item.statusId;
        });
      } else {
        data = await this.$api.gsn.getListAllSupplierApply(params);
        data.rows.forEach((item) => {
          item.appStatus = item.statusId;
          item.zeroFlag = 1
        });
      }
      if (data.rows.length == 0) {
        this.finished = true
      }
      this.loading = false;
      this.formConfig.data = this.formConfig.data.concat(data.rows);
    },
    onCancel() {
      this.conditionsFlag = false;
      this.formConfig.data = [];
      this.pageIndex = 1;
      this.getList();
    },
    onReset() {
      this.getUserDep();
      (this.createDate = ''),
        (this.auditDate = ''),
        (this.pageIndex = 1),
        (this.pageSize = 10),
        (this.statusType = 1),
        (this.sort = 0),
        (this.sortType = 0),
        (this.submitBeginTime = ""),
        (this.submitEndTime = ""),
        (this.approveBeginTime = ""),
        (this.approveEndTime = "");
      this.formConfig.data = [];
      this.conditionsFlag = false;
      this.getList();
    },
    async getUserDep() {
      let data = await this.$api.gsn.getUserDep();
      this.userDep = data.departments;
      if (data.departments && data.departments.length > 0) {
        this.enterpriseId = data.departments[0].enterpriseId;
        this.departmentId = data.departments[0].id;
      }
    },
    async getListMenu() {
      const params = {
        parentMenuId: "30",
        enterpriseId: this.departmentId,
      };
      let data = await this.$api.gsn.getListMenu(params);
      this.classification = data;
      this.classificationType = data[0].classificationId;
    },
    async getList() {
      this.isEmpty = false;
      this.description = '正在加载'
      let params = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
        auditFlag: this.statusType, // 审核状态（1审核中/2审核通过）
        type: "1",
        enterpriseId: this.enterpriseId,
        sortAttr: this.sortType,
        sortWay: this.sort,
        createDateBegin: this.submitBeginTime,
        createDateEnd: this.submitEndTime,
        auditDateBegin: this.approveBeginTime,
        auditDateEnd: this.approveEndTime,
      };
      let data;
      if (this.statusType === 2) {
        params.isPhone = "true"
        data = await this.$api.gsn.getRiskQueryList(params);
        data.rows.forEach((item) => {
          item.appStatus = item.statusId;
        });
      } else {
        data = await this.$api.gsn.getRiskApplyList(params);
        data.rows.forEach((item) => {
          item.appStatus = item.statusId;
          item.zeroFlag = 1
        });
      }

      this.isLoading = false;
      if (data.rows.length > 0) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
        this.description = '没有需要审批的单据'
      }
      this.formConfig.data = data.rows;
      this.formConfig.total = Math.ceil(data.total / this.pageSize)
    },
    loadList() {
      this.onReset();
    },
    handlePop() {
      this.popshow = true;
    },
    onClose() {
      this.popshow = false;
    },
    async moreAuditApply(ids) {
      this.$api.gsn.auditRiskApplyInfoAuditApplyList(ids).then(() => {
        Toast.success('提交成功')
        this.onReset()
      })
    }
  },
};
</script>
